import { DashboardModule, Grid } from '@campaignhub/suit-theme'

import useSelector from '@hooks/useSelector'

import Tile from './Tile'

const Campaigns = () => {
  // This is just an example, don't do this in practice
  const { campaigns } = useSelector(reduxState => reduxState.entities)

  return (
    <DashboardModule
      headerRight={<DashboardModule.Link href="#/">View All</DashboardModule.Link>}
      title="Campaigns"
    >
      <Grid
        gridGap="medium"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(240px, 1fr))"
        padding="large"
      >
        {Object.values(campaigns).map(campaign => (
          <Tile campaign={campaign} href={`#/campaigns/${campaign.id}/edit`} key={campaign.id} />
        ))}
      </Grid>
    </DashboardModule>
  )
}

export default Campaigns
