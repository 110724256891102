import mainNavigationStyle from './styles/mainNavigation.module.scss'

const brand = {
  key: 'default',
  logoMark: {
    fillColor: 'white',
    logoKey: process.env.REACT_APP_APPLICATION_NAME,
  },
  styles: {
    mainNavigation: mainNavigationStyle,
  },
  title: 'Default',
}

export default brand
