import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EditPage from './screens/EditPage'
import NewPage from './screens/NewPage'

const CampaignRoutes = () => (
  <Routes>
    <Route path="/edit" element={<EditPage />} />
    <Route path="/new" element={<NewPage />} />
  </Routes>
)

export default CampaignRoutes
