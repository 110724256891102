import React from 'react'
import PropTypes from 'prop-types'

import ArtworkRoutes from './routes'

const ArtworkBuilder = (props) => {
  const { prop } = props

  return (
    <ArtworkRoutes />
  )
}

ArtworkBuilder.propTypes = {}

export default ArtworkBuilder
