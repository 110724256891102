import { EntitiesState } from '../entities'
import * as ModuleStates from './types'

// START MODULE IMPORTS
import * as campaigns from './campaign'
import * as entities from '../entities'
import * as navigation from './navigation'
// END MODULE IMPORTS

export type RootReducerState = {
// START ROOT REDUCER STATE
  campaigns: ModuleStates.CampaignModuleState,
  entities: EntitiesState,
  navigation: { showNavigation: boolean },
// END ROOT REDUCER STATE
}

const modules = {
// START MODULES LIST
  campaigns,
  entities,
  navigation,
// END MODULES LIST
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
