import { useSelector } from 'react-redux'

const isEncodedRedirect = redirect => redirect && redirect !== decodeURIComponent(redirect)

export const buildCreateArtworkUrl = (orderItem, campaign, options = {}) => {
  const { redirectUrl } = options || {}

  if (!orderItem){
    return null
  }

  let url = `#/artwork/new?campaignId=${campaign.id}&orderItemId=${orderItem.id}`
  if (redirectUrl){
    const encodedRedirectUrl = isEncodedRedirect(redirectUrl) ? redirectUrl : encodeURIComponent(redirectUrl)
    url += `&redirect=${encodedRedirectUrl}`
  }

  return url
}

export const buildEditArtworkUrl = (artwork, campaign, options = {}) => {
  const { redirectUrl } = options || {}

  if (!artwork){
    return null
  }

  let url = `#/artwork/edit?artworkId=${artwork.id}&campaignId=${campaign.id}`
  if (redirectUrl){
    const encodedRedirectUrl = isEncodedRedirect(redirectUrl) ? redirectUrl : encodeURIComponent(redirectUrl)
    url += `&redirect=${encodedRedirectUrl}`
  }

  return url
}

const useRelations = (artwork) => {
  const { campaignId } = artwork || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { agencies, campaigns } = entities

  const campaign = campaigns[campaignId] || {}
  const agency = agencies[campaign.agencyId] || {}

  return {
    agency,
    campaign,
  }
}
function useArtworkBuilderPaths(artwork, orderItem){
  const relations = useRelations(artwork)
  const { agency, campaign } = relations

  return {
    agency,
    callbacks: {
      buildCreateArtworkUrl,
      buildEditArtworkUrl,
    },
    campaign,
    createArtworkUrl: buildCreateArtworkUrl(orderItem),
    editArtworkUrl: buildEditArtworkUrl(artwork, campaign),
  }
}

export default useArtworkBuilderPaths
