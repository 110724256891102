import { useDeepEffect, useSetState } from '@campaignhub/react-hooks'

import reduxModules from '@redux/modules'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import type { ActionResult, EntityOptions, RootReducerState } from '@redux/modules/types'

type RootReducerModule = Omit<RootReducerState, 'entities' | 'navigation'>

type UseReduxActionOptions = {
  dispatchAction?: (action: Function, requestOptions: EntityOptions) => Promise<ActionResult<object | object[]>>,
  shouldPerformFn?: (entityReducer: RootReducerModule[keyof RootReducerModule]) => boolean,
  skipLoadedForKeysCheck?: boolean,
}

function sortRequestOptions(requestOptions: EntityOptions): EntityOptions {
  const sortedKeys = Object.keys(requestOptions).sort()

  return sortedKeys.reduce((sortedRequestOptions, key) => {
    sortedRequestOptions[key] = requestOptions[key]
    return sortedRequestOptions
  }, {})
}

function buildEntityKey(requestOptions: EntityOptions) {
  const sortedRequestOptions = sortRequestOptions(requestOptions)
  return JSON.stringify(sortedRequestOptions)
}

function dispatchAction(
  action: (reqOptions: EntityOptions) => Promise<ActionResult<object | object[]>>,
  requestOptions: EntityOptions,
  options: UseReduxActionOptions,
): Promise<ActionResult<object | object[]>> {
  const { dispatchAction: customDispatchAction } = options || {}

  if (customDispatchAction){
    return customDispatchAction(action, requestOptions)
  }

  return action(requestOptions)
}

function validateShouldPerform(
  action: Function,
  entityReducer: RootReducerModule[keyof RootReducerModule],
  loadKey: string,
  hasDeps: boolean,
  options: UseReduxActionOptions,
): boolean {
  const { shouldPerformFn, skipLoadedForKeysCheck } = options || {}
  const {
    errors, loaded, loadedForKeys = [], loading,
  } = entityReducer

  // If option skipLoadedForKeysCheck is passed we only run the shouldPerformFn or return the default check

  if (shouldPerformFn){
    const shouldPerformResult = shouldPerformFn(entityReducer)
    return skipLoadedForKeysCheck ? shouldPerformResult : shouldPerformResult && !loadedForKeys?.includes(loadKey)
  }

  if (!skipLoadedForKeysCheck){
    return action && !loadedForKeys.includes(loadKey)
  }

  return (action && !loading && (hasDeps || !loaded) && !errors?.length)
}

/*
 * This hook can be used in the simple form:
 *
 * useReduxAction('providers', 'loadProviders', providerOptions, [])
 *
 * or you can pass custom functions for more advanced requests:
 *
 * useReduxAction('users', 'loadProviders', userOptions, [campaign.id], {
 *  dispatchAction: (action, requestOptions) => action('Campaign', campaign.id, requestOptions),
 *  shouldPerformFn: (entityReducer) => {
 *    const { loading, errors, loadedUsersForEntities } = entityReducer
 *    const entityKey = `Campaign${campaign.id}`
 *    return (campaign.id && !loading && !errors.length && !loadedUsersForEntities.includes(entityKey))
 *  },
 * })
 */

const defaultState = {
  result: {},
}

function useReduxAction<EntityKeyType extends keyof RootReducerModule>(
  entityKey: EntityKeyType,
  actionName: string,
  requestOptions: EntityOptions = {},
  deps: any[] = [],
  options: UseReduxActionOptions = {},
): RootReducerModule[EntityKeyType] & { result?: object } {
  const [state, setState] = useSetState(defaultState)
  const { result } = state

  const entityReducer = useSelector(reduxState => reduxState[entityKey]) || {}

  const dispatch = useDispatch()

  useDeepEffect(() => {
    const actions = reduxModules[entityKey]
    const action = actions ? actions[actionName] : null

    // Update request options with entity key containing stringified, sorted requestOptions
    const loadKey = requestOptions.entityKey || buildEntityKey(requestOptions)
    const updateRequestOptions = { ...requestOptions, entityKey: loadKey }

    // We need to be able to force reload if required
    // If we have deps these will cause the useDeepEffect function to run again
    // which means something we are watching has changed so we can reload.
    const shouldPerform = validateShouldPerform(action, entityReducer, loadKey, !!deps.length, options)

    if (shouldPerform){
      dispatch(dispatchAction(action, updateRequestOptions, options))
        .then((response: ActionResult<object | object[]>) => {
          setState({ result: response })
        })
    }
  }, [dispatch, ...deps])

  // Order is important ;)
  return { ...entityReducer, ...result }
}

export default useReduxAction
