import React from 'react'
import { toast, ToastContainer } from 'react-toastify'

import { ArtworkEditor } from '@campaignhub/artwork-builder'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import '@campaignhub/artwork-builder/dist/styles.css'

import useArtworkBuilder from '@hooks/useArtworkBuilder'

const CloseButton = ({ closeToast }) => (
  <a onClick={closeToast}>
    <FontAwesomeIcon icon={faTimes} />
  </a>
)

const selectSavedArtworkPage = async (savedArtworkPage, createFn, loadShortcodeDataFn, selectArtworkPageId, requestOptions, setState) => {
  const { artworkConfig, ...options } = requestOptions || {}
  const { campaigntrackOrderItemId } = artworkConfig || {}

  const shortcodeDataResult = await loadShortcodeDataFn({ id: campaigntrackOrderItemId })
  const { data: customShortcodeData } = shortcodeDataResult

  const mergedOptions = {
    ...options,
    custom_data: customShortcodeData,
  }

  createFn(savedArtworkPage, mergedOptions).then((response) => {
    const { success, data, errors } = response
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    selectArtworkPageId(data.id)

    setState({ showSelectTemplateModal: false })
  })
}

const selectTemplatePage = async (templatePage, createFn, loadShortcodeDataFn, selectArtworkPageId, requestOptions, setState) => {
  const { artworkConfig, ...options } = requestOptions || {}
  const { campaigntrackOrderItemId } = artworkConfig || {}

  const shortcodeDataResult = await loadShortcodeDataFn({ id: campaigntrackOrderItemId })
  const { data: customShortcodeData } = shortcodeDataResult

  const mergedOptions = {
    ...options,
    custom_data: customShortcodeData,
  }

  createFn(templatePage, mergedOptions).then((response) => {
    const { success, data, errors } = response
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    selectArtworkPageId(data.id)

    setState({ showSelectTemplateModal: false })
  })
}

const replaceArtworkPageTemplatePage = async (
  artworkPage,
  templatePage,
  updateFn,
  loadShortcodeDataFn,
  requestOptions,
  setState,
) => {
  const { artworkConfig, ...options } = requestOptions || {}
  const { campaigntrackOrderItemId } = artworkConfig || {}

  const shortcodeDataResult = await loadShortcodeDataFn({ id: campaigntrackOrderItemId })
  const { data: customShortcodeData } = shortcodeDataResult

  const mergedOptions = {
    ...options,
    custom_data: customShortcodeData,
  }

  updateFn(artworkPage, templatePage, mergedOptions).then((response) => {
    const { success, errors } = response
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showSelectTemplateModal: false })
  })
}

const EditPage = () => {
  const {
    callbacks: {
      deleteArtwork, fetchEditorOptions, goBack, loadShortcodeData,
    },
  } = useArtworkBuilder()

  return (
    <ArtworkEditor
      callbacks={{
        deleteArtwork,
        fetchEditorOptions,
        goBack,
        replaceArtworkPageTemplatePage: (artworkPage, templatePage, updateFn, requestOptions, setState) => replaceArtworkPageTemplatePage(artworkPage, templatePage, updateFn, loadShortcodeData, requestOptions, setState),
        selectSavedArtworkPage: (savedArtworkPage, createFn, selectArtworkPageId, requestOptions, setState) => selectSavedArtworkPage(savedArtworkPage, createFn, loadShortcodeData, selectArtworkPageId, requestOptions, setState),
        selectTemplatePage: (templatePage, createFn, selectArtworkPageId, requestOptions, setState) => selectTemplatePage(templatePage, createFn, loadShortcodeData, selectArtworkPageId, requestOptions, setState),
      }}
    >
      <ArtworkEditor.Header />
      <ArtworkEditor.Canvas />

      <ToastContainer closeButton={<CloseButton />} hideProgressBar autoClose={5000} />
    </ArtworkEditor>
  )
}

export default EditPage
