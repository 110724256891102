const CHANGE_PARAM = 'campaigntrack/navigation/CHANGE_PARAM'

type ReducerAction = {
  name?: string,
  type?: string,
  value?: any,
}

type InitialReducerState = {
  showNavigation: boolean,
}

const initialState = {
  showNavigation: false,
}

// Actions
export function changeParam(name: string, value: any) {
  return {
    type: CHANGE_PARAM,
    name,
    value,
  }
}

// Reducer
export default function reducer(state: InitialReducerState = initialState, action: ReducerAction = {}) {
  const { name, type, value } = action

  switch (type){
    case CHANGE_PARAM:
      return {
        ...state,
        [name]: value,
      }
    default:
      return state
  }
}
