import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as orderItemActions from '@redux/modules/orderItem'

export const linkArtworkToOrderItem = (orderItem, artworkId, dispatch) => {
  const { linkArtworkToOrderItem: linkFn } = orderItemActions
  return dispatch(linkFn(orderItem, artworkId))
}

export const loadShortcodeData = (orderItem, dispatch) => {
  const { loadShortcodeData: loadFn } = orderItemActions
  return dispatch(loadFn(orderItem))
}

function useOrderItem(initOrderItem){
  const { entity: orderItem } = useLatestEntity(initOrderItem, 'orderItems')

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      linkArtworkToOrderItem: artworkId => linkArtworkToOrderItem(orderItem.id, artworkId, dispatch),
      loadShortcodeData: () => loadShortcodeData(orderItem, dispatch),
    },
    orderItem,
  }
}

export default useOrderItem
