import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

import { Box, Image, Text } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'

import type { CampaignModel } from '@models/campaign'
import placeholderImageUrl from './assets/campaign_image_400x400.png'

import styles from './styles.module.scss'

interface TileProps {
  campaign: CampaignModel,
  href: string,
}

const Tile = (props: TileProps) => {
  const {
    campaign,
    href,
  } = props

  const campaignPayload = useCampaign(campaign)
  const { address, default_image, suburb } = campaignPayload

  return (
    <Image
      borderRadius={5}
      className={
        default_image ? styles.root : classnames(styles.root, styles.noImage)
      }
      placeholderUrl={placeholderImageUrl}
      url={default_image}
    >
      <span className={styles.gradient} />
      <a href={href} className={styles.linkOverlay} />

      <Box
        className={styles.details}
        flexDirection="column"
        padding="large"
        position="relative"
      >
        <Text className={styles.suburb} marginBottom="medium">
          {suburb}
        </Text>
        <Text className={styles.address} fontSize="medium">
          {address}
        </Text>

        <FontAwesomeIcon className={styles.angleRight} icon={faAngleRight} />
      </Box>
    </Image>
  )
}

export default Tile
