import { useContext } from 'react'
import {
  Box,
  Button,
  DashboardModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import type { DefaultContext } from '@contexts/pageContext'
import type { PageContextPayload } from '../../index'

interface ToolsProps {
  prop: any,
}

const Tools = (props: ToolsProps) => {
  const { prop } = props

  // You would not do this in production
  // It is just a sample of how you can use page context
  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const { callbacks: { showSampleModal } } = pageContext

  return (
    <DashboardModule title="Actions">
      <Box flexDirection="column" padding="large">
        <Button buttonStyle="secondary" onClick={() => showSampleModal()} size="medium">
          Show Modal
        </Button>
      </Box>
    </DashboardModule>
  )
}

Tools.propTypes = {}

export default Tools
