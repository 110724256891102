import { digObject, getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import { useThunkDispatch } from '@campaignhub/react-hooks'

import { buildCreateArtworkUrl } from '@hooks/useArtworkBuilderPaths'
import { loadShortcodeData } from '@hooks/useOrderItem'
import useRedirector from '@hooks/useRedirector'

import * as artworkActions from '@redux/modules/artwork'
import * as campaignActions from '@redux/modules/campaign'
import * as orderItemActions from '@redux/modules/orderItem'

const fetchEditorOptions = (artwork, campaign, redirect, dispatch) => {
  const { artworkBuilderConfig: loadFn } = campaignActions

  return dispatch(loadFn(artwork, campaign)).then((response) => {
    const { data, errors, success } = response
    if (success){
      const mergedConfig = {
        ...data,
        redirectUrl: redirect,
      }

      return { success: true, data: mergedConfig }
    }

    return { success: false, errors }
  })
}

const fetchNewArtworkOptions = (orderItem, redirect, dispatch) => {
  const { artworkBuilderConfig: loadFn } = orderItemActions

  return dispatch(loadFn(orderItem)).then((response) => {
    const { data, errors, success } = response
    if (success){
      const mergedConfig = {
        ...data,
        redirectUrl: redirect,
      }

      return { success: true, data: mergedConfig }
    }

    return { success: false, errors }
  })
}

const createArtworkOrder = (artwork, artworkPages, dispatch) => {
  const { createArtworkOrder: createFn } = artworkActions
  return dispatch(createFn(artwork, artworkPages))
}

const deleteArtwork = async (campaign, deleteFn, editorContext, redirect, dispatch) => {
  const { unlinkArtworkFromOrderItem: unlinkFn } = orderItemActions

  const campaigntrackOrderItemId = digObject(editorContext, 'selectedArtworkConfig.campaigntrackOrderItemId')
  const artwork = digObject(editorContext, 'selectedArtwork', {})

  // Unlink Artwork from Order Item
  const unlinkResult = await dispatch(unlinkFn(campaigntrackOrderItemId, artwork.id))

  const { success: unlinkSuccess } = unlinkResult
  if (!unlinkSuccess){
    console.error(`Could not unlink artwork #${artwork.id} from order item #${campaigntrackOrderItemId}`)
    return
  }

  // Delete Artwork
  const deleteResult = await deleteFn()
  const { success: deleteSucess } = deleteResult
  if (!deleteSucess){
    console.error(`Could not delete artwork #${artwork.id}`)
    return
  }

  // Redirect
  const createArtworkUrl = buildCreateArtworkUrl({ id: campaigntrackOrderItemId }, campaign, { redirectUrl: redirect })
  window.location.hash = createArtworkUrl
}

const goBack = (editorContext, dispatch, redirectFn) => {
  const {
    artworkPayload: {
      artwork,
      filteredArtworkPages,
    },
  } = editorContext

  const mappedArtworkPages = filteredArtworkPages.map(artworkPage => ({
    id: artworkPage.id,
    templateId: artworkPage.template_page_id,
  }))

  return createArtworkOrder(artwork, mappedArtworkPages, dispatch).then((response) => {
    const { success } = response

    if (success){
      redirectFn()
    }
  })
}

function useArtworkBuilder(){
  const { artworkId, campaignId, orderItemId, redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'artworkId',
    'campaignId',
    'orderItemId',
    'redirect',
  ])

  const { callbacks: { redirect: redirectFn } } = useRedirector({ redirectUrl: redirect })

  const dispatch = useThunkDispatch()

  const artwork = { id: artworkId }

  const campaign = { id: campaignId }

  return {
    callbacks: {
      deleteArtwork: (deleteFn, editorContext) => deleteArtwork(campaign, deleteFn, editorContext, redirect, dispatch),
      fetchEditorOptions: () => fetchEditorOptions(artwork, campaign, redirect, dispatch),
      fetchNewArtworkOptions: () => fetchNewArtworkOptions({ id: orderItemId }, redirect, dispatch),
      goBack: editorContext => goBack(editorContext, dispatch, redirectFn),
      loadShortcodeData: orderItem => loadShortcodeData(orderItem, dispatch),
    },
    campaign,
    orderItemId,
    redirect,
  }
}

export default useArtworkBuilder
