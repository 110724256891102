// Search for strings below (Match case) and replace with correct name
// 1. EntityName
// 2. entityName
// 3. CreateOrEditEntityNameModal
// 4. delete comments
// ---------------------------
import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

// import useEntityName, { useEntityNameForm } from '@hooks/useEntityName'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateOrEditEntityNameModal'

type ModalCallbacks = {
  closeModal: () => void,
  createEntityName: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditEntityNameModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createEntityName } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { entityName: initEntityName } = modalPayload

  const {
    callbacks: { createEntityName: createFn },
    creating,
    entityName,
    updating,
  } = useEntityName(initEntityName)

  const {
    entityState,
    entityState: {
      title,
    },
    errors,
    handlers,
    saveEnabled,
  } = useEntityNameForm(entityName, { validateOn: 'change' })

  // To be used with handleCallbackAction
  const createEntityNamePayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'EntityName Created',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Modal" titleSecondLine="Name" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Title" errorMessage={errors.title}>
            <input name="title" type="text" value={title} {...handlers} />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => createEntityName(createEntityNamePayload)}
          size="large"
        >
          {saveEnabled ? 'Create EntityName' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditEntityNameModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
