import { Route, Routes } from 'react-router-dom'

import Overview from './screens/Overview'

const CampaignRoutes = () => (
  <Routes>
    <Route path="/" element={<Overview />} />
  </Routes>
)

export default CampaignRoutes
