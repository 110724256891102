type HandleErrorParams = {
  errors?: string[],
}

export const handleError = (data: HandleErrorParams) => {
  console.log('Raw Error', data)
  return data.errors || ['Something went wrong']
}

export default handleError
