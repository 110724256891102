import { useMemo } from 'react'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box,
  Button,
  Columns,
  DashboardModule,
  ModalContext,
  ModuleBlankState,
  PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useReduxAction from '@hooks/useReduxAction'

import SampleModal from '@modals/SampleModal'

import Campaigns from './components/Campaigns'
import Tools from './components/Tools'

import TasksImageUrl from './assets/tasks.svg'

export type PageContextPayload = {
  callbacks: {
    showSampleModal: () => void,
  },
}

const defaultState = {
  showSampleModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    SampleModal: {
      closeModal: () => setState({ showSampleModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Overview = () => {
  const [state, setState] = useSetState(defaultState)
  const { showSampleModal } = state

  useReduxAction('campaigns', 'loadCampaigns', { includeCampaignImage: true })

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showSampleModal: (payload) => {
        setModalData('SampleModal', payload)
        setState({ showSampleModal: true })
      },
    },
  }), []) // ModalContext Dependency if needed

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          actionContent={(
            <Button buttonStyle="secondaryCreate" size="medium">
              Create
            </Button>
          )}
          activeTabBarItemKey="dashboard"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          tabBarItems={[
            {
              href: '#/',
              icon: faHome,
              key: 'dashboard',
              title: 'Dashboard',
            },
          ]}
          title="Client Dashboard"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <Campaigns />

              <DashboardModule
                headerRight={<DashboardModule.Link href="#/">Module Link</DashboardModule.Link>}
                title="Module"
              >
                <ModuleBlankState imageUrl={TasksImageUrl}>
                  <ModuleBlankState.Title>No Tasks to Display</ModuleBlankState.Title>

                  <ModuleBlankState.Paragraph>
                    Tasks are created when you assign a workflow to a campaign. Workflow Tasks can be located in the
                    campaign menu.
                  </ModuleBlankState.Paragraph>
                </ModuleBlankState>
              </DashboardModule>
            </Columns.Main>

            <Columns.Sidebar>
              <Tools />
            </Columns.Sidebar>
          </Columns>
        </Box>

        <SampleModal callbacks={callbacks('SampleModal', setState)} showModal={showSampleModal} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview
