import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { getAccessToken } from '@functions/accessToken'

import AdminRoutes from '@sections/Admin/routes'
import ClientRoutes from '@sections/Client/routes'
import Login from '@sections/Auth/Login'

import Redirector from './components/Redirector'

const adminRoles = ['Admin', 'Support']

// When logging in the token is not instantly available
// We wait and check 0.1 second later for it to be available

type SetAccessToken = (token: string | null) => React.Dispatch<React.SetStateAction<string | null>>

const waitForAccessToken = (setAccessToken: SetAccessToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token){
      clearInterval(intervalId)
      setAccessToken(token)
    }
  }, 100)
}

const AuthenticationController = () => {
  const [accessToken, setAccessToken] = useState(null)

  const auth0Payload = useAuth0()
  const { isAuthenticated, user } = auth0Payload

  const { pathname } = useLocation()

  useEffect(() => {
    waitForAccessToken(setAccessToken)
  }, [])

  const { 'https://www.realhubapp.com/role': userRole } = user || {}

  if (pathname === '/redirector'){
    return <Redirector />
  }

  if (!accessToken || !isAuthenticated){
    return <Login />
  }

  if (adminRoles.includes(userRole)){
    return <AdminRoutes />
  }

  return <ClientRoutes />
}

export default AuthenticationController
