import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Client/components/MainNavigation'

import DashboardRoutes from './routes'

interface DashboardProps {
  prop: any,
}

const Dashboard = (props: DashboardProps) => {
  const { prop } = props

  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <DashboardRoutes />
      </MainContent>
    </>
  )
}

export default Dashboard
