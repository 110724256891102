import { useAuth0 } from '@auth0/auth0-react'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, ModuleBlankState, PageHeader,
} from '@campaignhub/suit-theme'

import TasksImageUrl from './assets/tasks.svg'

const Overview = () => {
  const { logout } = useAuth0()

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faHome,
            key: 'dashboard',
            title: 'Dashboard',
          },
        ]}
        title="Admin Dashboard"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <DashboardModule
              headerRight={<DashboardModule.Link href="#/">Module Link</DashboardModule.Link>}
              title="Module"
            >
              <ModuleBlankState imageUrl={TasksImageUrl}>
                <ModuleBlankState.Title>No Tasks to Display</ModuleBlankState.Title>

                <ModuleBlankState.Paragraph>
                  Tasks are created when you assign a workflow to a campaign. Workflow Tasks can be located in the
                  campaign menu.
                </ModuleBlankState.Paragraph>
              </ModuleBlankState>
            </DashboardModule>

            <DashboardModule
              headerRight={<DashboardModule.Link href="#/">Module Link</DashboardModule.Link>}
              title="Module"
            >
              <ModuleBlankState imageUrl={TasksImageUrl}>
                <ModuleBlankState.Title>No Tasks to Display</ModuleBlankState.Title>

                <ModuleBlankState.Paragraph>
                  Tasks are created when you assign a workflow to a campaign. Workflow Tasks can be located in the
                  campaign menu.
                </ModuleBlankState.Paragraph>
              </ModuleBlankState>
            </DashboardModule>
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Button buttonStyle="secondary" onClick={() => logout({ returnTo: window.location.origin })} size="medium">
                  Log Out
                </Button>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Overview
