import React, { useEffect } from 'react'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import {
  Box, Heading, Image, LoadingBubbles,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from './assets/logo-mark.svg'

const Redirector = () => {
  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'redirect',
  ])

  useEffect(() => {
    if (redirect){
      window.location.href = redirect
    }
  }, [redirect])

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />

        <Heading textProps={{ color: 'bodyFontLightColor', marginTop: 'xlarge' }}>
          Redirecting...
        </Heading>

        <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />
      </Box>
    </Box>
  )
}

Redirector.propTypes = {}

export default Redirector
