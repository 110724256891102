import { Route, Routes } from 'react-router-dom'

import Admin from '.'
import Dashboard from './packs/Dashboard'

const AdminRoutes = () => (
  <Admin>
    <Routes>
      <Route path="/" element={<Dashboard />} />
    </Routes>
  </Admin>
)

export default AdminRoutes
