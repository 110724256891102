import React from 'react'
import PropTypes from 'prop-types'
import { toast, ToastContainer } from 'react-toastify'

import { NewArtwork } from '@campaignhub/artwork-builder'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import useArtworkBuilder from '@hooks/useArtworkBuilder'
import useOrderItem from '@hooks/useOrderItem'
import { buildEditArtworkUrl } from '@hooks/useArtworkBuilderPaths'

import 'react-toastify/dist/ReactToastify.css'
import '@campaignhub/artwork-builder/dist/styles.css'
import '@styles/toast.scss'

const CloseButton = ({ closeToast }) => (
  <a onClick={closeToast}>
    <FontAwesomeIcon icon={faTimes} />
  </a>
)

const goBack = (campaign, redirect) => {
  if (redirect){
    window.location.href = redirect
    return
  }

  window.location.hash = `/campaigns/${campaign.id}/edit`
}

const linkArtworkToOrderItem = (campaign, artwork, linkFn, redirect) => {
  linkFn(artwork.id).then((response) => {
    const { success, errors = [] } = response

    if (!success){
      toast.warn(errors[0])
      return
    }

    const editArtworkUrl = buildEditArtworkUrl(artwork, campaign, { redirectUrl: redirect || null })
    window.location.href = editArtworkUrl
  })
}

const selectSavedArtwork = async (campaign, savedArtwork, createFn, loadShortcodeDataFn, linkFn, redirect) => {
  const shortcodeDataResult = await loadShortcodeDataFn()
  const { data: customShortcodeData } = shortcodeDataResult

  const options = {
    custom_data: customShortcodeData,
    create_attached_item_if_missing: true,
  }

  createFn(savedArtwork, options).then((response) => {
    const { success, data: artwork, errors = [] } = response
    if (!success){
      toast.warn(errors[0])
      return
    }

    // Link the Artwork to CT Order Item
    linkArtworkToOrderItem(campaign, artwork, linkFn, redirect)
  })
}

const selectTemplatePage = async (campaign, templatePage, createFn, loadShortcodeDataFn, linkFn, options, redirect) => {
  const shortcodeDataResult = await loadShortcodeDataFn()
  const { data: customShortcodeData } = shortcodeDataResult

  const mergedOptions = {
    ...options,
    create_attached_item_if_missing: true,
    custom_data: customShortcodeData,
  }

  createFn(templatePage, mergedOptions).then((response) => {
    const { success, data: artwork, errors = [] } = response
    if (!success){
      toast.warn(errors[0])
      return
    }

    // Link the Artwork to CT Order Item
    linkArtworkToOrderItem(campaign, artwork, linkFn, redirect)
  })
}

const NewPage = (props) => {
  const { children } = props

  const {
    callbacks: { fetchNewArtworkOptions },
    campaign,
    orderItemId,
    redirect,
  } = useArtworkBuilder()

  const {
    callbacks: {
      linkArtworkToOrderItem: linkFn,
      loadShortcodeData: loadShortcodeDataFn,
    },
  } = useOrderItem({ id: orderItemId })

  return (
    <NewArtwork
      callbacks={{
        artworkUploadSuccess: () => goBack(campaign, redirect),
        closeSelectTemplateModal: () => goBack(campaign, redirect),
        fetchEditorOptions: fetchNewArtworkOptions,
        selectSavedArtwork: (savedArtwork, createFn) => selectSavedArtwork(campaign, savedArtwork, createFn, loadShortcodeDataFn, linkFn, redirect),
        selectTemplatePage: (templatePage, createFn, _, options) => selectTemplatePage(campaign, templatePage, createFn, loadShortcodeDataFn, linkFn, options, redirect),
      }}
    >
      {children}

      <ToastContainer closeButton={<CloseButton />} hideProgressBar autoClose={5000} />
    </NewArtwork>
  )
}

NewPage.propTypes = {
  children: PropTypes.node,
}

export default NewPage
