import {
  faCamera,
  faGavel,
  faHome,
  faLifeRing,
  faList,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Overview',
          visible: true,
        },
      ],
    },
    {
      key: 'section-one',
      title: 'Section',
      visible: true,
      items: [
        {
          href: '#/campaigns/1/edit',
          icon: faHome,
          key: 'campaign',
          title: 'Campaign',
          visible: true,
        },
        {
          icon: faList,
          key: 'nested',
          title: 'Nested Items',
          visible: true,
          items: [
            {
              href: '#/',
              icon: faGavel,
              key: 'nested-one',
              title: 'Nested One',
              visible: true,
            },
            {
              href: '#/',
              icon: faCamera,
              key: 'nested-two',
              title: 'Nested Two',
              visible: true,
            },
          ],
        },
      ],
    },
    {
      key: 'support',
      title: 'Support',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faLifeRing,
          key: 'training',
          title: 'Training',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
