import { useAuth0 } from '@auth0/auth0-react'

const buildRedirectorUrl = (redirectUrl) => {
  const { origin } = window.location
  const encodedRedirect = encodeURIComponent(redirectUrl)

  return `${origin}/#/redirector?redirect=${encodedRedirect}`
}

const logoutAndRedirect = (logout, redirectUrl) => {
  logout({
    returnTo: buildRedirectorUrl(redirectUrl),
  })
}

const redirect = (redirectUrl) => {
  window.location.href = buildRedirectorUrl(redirectUrl)
}

function useRedirector(options = {}){
  const { redirectUrl } = options || {}

  const { logout } = useAuth0()

  return {
    callbacks: {
      logoutAndRedirect: () => logoutAndRedirect(logout, redirectUrl),
      redirect: () => redirect(redirectUrl),
    },
  }
}

export default useRedirector
