const splitAddressString = (string) => {
  if (!string) return {}

  const [address, suburb] = string.split(',')

  return {
    address,
    suburb: suburb || 'Agency Marketing',
  }
}

function useCampaign(campaign){
  const { name } = campaign

  const addressParts = splitAddressString(name)

  return {
    ...addressParts,
    campaign,
  }
}

export default useCampaign
