import React from 'react'

import { useRouterScrollToTop } from '@campaignhub/react-hooks'

interface ClientProps {
  children: React.ReactNode,
}

const Client = (props: ClientProps) => {
  const { children } = props

  // Scroll Page to top when route changes
  useRouterScrollToTop()

  return children
}

export default Client
