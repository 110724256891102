import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, ModuleBlankState, PageHeader,
} from '@campaignhub/suit-theme'

interface OverviewProps {
  prop: any,
}

const Overview = (props: OverviewProps) => {
  const { prop } = props

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/campaigns/451/edit',
            icon: faHome,
            key: 'dashboard',
            title: 'Dashboard',
          },
          {
            href: '#/campaigns/451/edit/artwork-builder',
            icon: faHome,
            key: 'builder',
            title: 'Builder',
          },
        ]}
        title="Campaign Overview"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <DashboardModule
              headerRight={<DashboardModule.Link href="#/">Module Link</DashboardModule.Link>}
              title="Module"
            >
              <ModuleBlankState>
                <ModuleBlankState.Title>No Tasks to Display</ModuleBlankState.Title>

                <ModuleBlankState.Paragraph>
                  Tasks are created when you assign a workflow to a campaign. Workflow Tasks can be located in the
                  campaign menu.
                </ModuleBlankState.Paragraph>
              </ModuleBlankState>
            </DashboardModule>

            <DashboardModule
              headerRight={<DashboardModule.Link href="#/">Module Link</DashboardModule.Link>}
              title="Module"
            >
              <ModuleBlankState>
                <ModuleBlankState.Title>No Tasks to Display</ModuleBlankState.Title>

                <ModuleBlankState.Paragraph>
                  Tasks are created when you assign a workflow to a campaign. Workflow Tasks can be located in the
                  campaign menu.
                </ModuleBlankState.Paragraph>
              </ModuleBlankState>
            </DashboardModule>
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Button buttonStyle="secondary" icon={<FontAwesomeIcon icon={faHome} />} onClick={null} size="medium">
                  Campaign Action
                </Button>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Overview
