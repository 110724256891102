import { Route, Routes } from 'react-router-dom'

import Client from '.'
import ArtworkBuilder from './packs/ArtworkBuilder'
import Campaign from './packs/Campaign'
import Dashboard from './packs/Dashboard'

const ClientRoutes = () => (
  <Client>
    <Routes>
      <Route path="/artwork/*" element={<ArtworkBuilder />} />
      <Route path="/campaigns/:campaignId/edit/*" element={<Campaign />} />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  </Client>
)

export default ClientRoutes
