import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
// START ENTITY DEFINITIONS
const campaign = new schema.Entity('campaigns')
const image = new schema.Entity('images')
const orderItem = new schema.Entity('orderItems')
const user = new schema.Entity('users')
// END ENTITY DEFINITIONS

// Export Schemas
export const Schemas = {
// START SCHEMA DEFINITION
  CAMPAIGN: campaign,
  CAMPAIGN_ARRAY: [campaign],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  ORDER_ITEM: orderItem,
  ORDER_ITEM_ARRAY: [orderItem],
  USER: user,
  USER_ARRAY: [user],
// END SCHEMA DEFINITION
}

export default Schemas
